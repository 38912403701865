<template>
  <div class="g-box g-error-box">
    <template v-if="$route.meta.forbidden">
      <div class="g-wrapper-txt">
        <el-result icon="error">
        </el-result>
      </div>
      <div class="g-wrapper">
        <div style="text-align: center;">
          <div class="code">没有权限访问</div>
          <el-button size="small" style="position:relative; top: 100px;" @click="handleBack">返回首页</el-button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="g-wrapper-txt">
        <el-result icon="error">

        </el-result>
      </div>
      <div class="g-wrapper">
        <div style="text-align: center;">
          <div class="code">抱歉 页面不存在</div>
          <el-button size="small" style="position:relative; top: 100px;" @click="handleBack">返回首页</el-button>
        </div>

      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'error-sub',
  components: {},
  directives: {},
  filters: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  beforeCreate () {
  },
  created () {
    console.log('r', this.$route)
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    handleBack () {
      this.$router.replace('/')
    }
  }
})
</script>

<style lang="scss" scoped>
  @import "./../assets/css/global-variable.scss";
  .g-error-box{
    background: #ffffff;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .g-wrapper{
    width: 100%;
    height: 300px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .g-wrapper .code{
    font-size: 36px;
    letter-spacing: 0px;
    margin-top: 20px;
    color: $fc-theme;
  }
  .g-wrapper-txt{
    position: relative;
    color: #666666;
    top: 100px;
    left: 0;
    right: 0;
    margin:0 auto;
    text-align: center;
    font-size: 24px;
  }
</style>
